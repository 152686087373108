var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"users-list","fluid":"","tag":"section"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('franquia-new')],1),_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"flat":"","solo":"","prepend-icon":"mdi-magnify","placeholder":"Digite algo","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"flat":"","outlined":"","no-results-text":"Nenhum resultado encontrado","headers":_vm.complex.headers,"search":_vm.search,"items":_vm.items,"footer-props":{
          'items-per-page-options': [
            10,
            25,
            50,
            { text: 'Todos', value: -1 },
          ],
        },"item-key":"name"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{ 'grey--text': props.item.disabled }},[_c('td',[_vm._v(" "+_vm._s(props.item.titulo)+" ")]),_c('td',[_vm._v(_vm._s(props.item.cidade))]),_c('td',[_vm._v(_vm._s(props.item.estado))]),_c('td',[_vm._v(_vm._s(props.item.numfranquiados))]),_c('td',[_c('franquia',{key:props.item['.key'],attrs:{"franquia":props.item}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"460"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true),model:{value:(props.item.dialog),callback:function ($$v) {_vm.$set(props.item, "dialog", $$v)},expression:"props.item.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Remover Franquia ")]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Ao remover a franquia, você estará removendo também os usuários vinculados e todos os outros dados referentes à franquia. ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){props.item.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.remover(props.item)}}},[_vm._v(" Remover ")])],1)],1)],1)],1)])]}}]),model:{value:(_vm.complex.selected),callback:function ($$v) {_vm.$set(_vm.complex, "selected", $$v)},expression:"complex.selected"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }