<template>
  <v-container id="users-list" fluid tag="section">
    <v-layout row wrap>
      <v-col cols="12">
        <!-- <base-material-card color="primary" outlined> -->
        <v-app-bar flat color="white">
          <v-spacer></v-spacer>
          <franquia-new ></franquia-new>
        </v-app-bar>
        <v-app-bar flat color="white">
          <v-text-field
            flat
            solo
            prepend-icon="mdi-magnify"
            placeholder="Digite algo"
            v-model="search"
            hide-details
            class=""
          ></v-text-field>
          <v-btn icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
        <!-- <v-card-text class="pa-0"> -->

        <v-data-table
          flat
          outlined
          no-results-text="Nenhum resultado encontrado"
          :headers="complex.headers"
          :search="search"
          :items="items"
          :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              { text: 'Todos', value: -1 },
            ],
          }"
          class="elevation-1"
          item-key="name"
          v-model="complex.selected"
        >
          <template slot="item" slot-scope="props">
            <tr :class="{ 'grey--text': props.item.disabled }">
              <td>
                {{ props.item.titulo }}
              </td>
              <td>{{ props.item.cidade }}</td>
              <td>{{ props.item.estado }}</td>
              <td>{{ props.item.numfranquiados }}</td>
              <td>
                <franquia :franquia="props.item" :key="props.item['.key']"></franquia>

                <v-dialog
                  v-model="props.item.dialog"
                  persistent
                  max-width="460"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      
                    >
                      <v-icon dark color="red"> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">
                      Remover Franquia
                    </v-card-title>
                    <v-card-text
                      ><v-alert type="error">
                        Ao remover a franquia, você estará removendo também os usuários vinculados e todos os outros dados referentes à franquia.
                      </v-alert></v-card-text
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="props.item.dialog = false">
                        Cancelar
                      </v-btn>
                      <v-btn color="primary" @click="remover(props.item)">
                        Remover
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- </v-card-text> -->
        <!-- </base-material-card> -->
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
import firebase from "firebase/app";
// import {admin} from "firebase-admin";
import { db } from "@/db";

// Utilities
import { mapState } from "vuex";
export default {
  components: {
    Franquia: () => import("@/views/dashboard/components/core/Franquia"),
    FranquiaNew: () => import("@/views/dashboard/components/core/Franquia"),
    MatriculaLote: () =>
      import("@/views/dashboard/components/core/MatriculaLote"),
    MatriculaLoteEmail: () =>
      import("@/views/dashboard/components/core/MatriculaLoteEmail"),
  },
  firebase: {
    items: db.ref("/franquias/"),
  },
  data() {
    return {
      dialog: false,
      search: "",
      items: [],
      complex: {
        selected: [],
        headers: [
          {
            text: "Franquia",
            value: "titulo",
          },
          {
            text: "Cidade",
            value: "cidade",
          },
          {
            text: "Estado",
            value: "estado",
          },
          {
            text: "Usuarios (Qtd)",
            value: "usuarios",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    remover(item) {
      let obj = item;
      const ref = "franquias/" + obj[".key"];
      firebase
        .database()
        .ref(ref)
        .remove()
        .then(() => {
          // Update successful.
          toastr.success("Franquia Excluída com sucesso!", "Confabular");
          this.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    duplicar(curso) {
      let newCurso = this.getValues(curso);
      newCurso.titulo = newCurso.titulo + " - Cópia";
      delete newCurso.matriculados;
      const ref = "cursos/";
      firebase
        .database()
        .ref(ref)
        .push(newCurso)
        .then(() => {
          // Update successful.
          toastr.success("Curso Duplicado com sucesso!", "Confabular");
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    getValues(object) {
      let keys = Object.keys(object);
      let values = Object.values(object);
      let obj = {};
      keys.forEach(function (key, index) {
        obj[key] = values[index];
      });
      return obj;
    },
    editar(user) {
      this.userEdit = user;
      this.dialogUser = true;
    },
    resetPassword(user) {
      toastr.info(
        "Solicitando redefinição de senha para: " +
          user.displayName +
          ". Aguarde ...",
        "Confabular"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/sendPasswordResetLink/" + user.email)
        .then((response) => {
          toastr.success(
            "E-mail com redefinição de senha para o usuário: " +
              user.displayName +
              " enviado com sucesso!",
            "Confabular"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "Confabular"
          );
        });
    },
    desativar(item) {
      toastr.info(
        "Desativando o curso: " + item.titulo + ". Aguarde ...",
        "Confabular"
      );
    },
    ativar(user) {
      toastr.info(
        "Ativando o cadastro de: " + user.displayName + ". Aguarde ...",
        "Confabular"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/enableUser/" + user.uid)
        .then((response) => {
          toastr.success(
            "Usuário: " + user.displayName + " ativado com sucesso!",
            "Confabular"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "Confabular"
          );
        });
    },
  },
  computed: {
    ...mapState(["user"]),
    userEdit: {
      get() {
        return this.$store.state.userEdit;
      },
      set(val) {
        this.$store.commit("SET_USER_EDIT", val);
      },
    },
    dialogUser: {
      get() {
        return this.$store.state.dialogUser;
      },
      set(val) {
        this.$store.commit("SET_DIALOG_USER", val);
      },
    },
  },
  created() {
    /*admin
      .auth()
      .getUsers()
      .then((getUsersResult) => {
        console.log("Successfully fetched user data:");
        getUsersResult.users.forEach((userRecord) => {
          console.log(userRecord);
        });

        console.log("Unable to find users corresponding to these identifiers:");
        getUsersResult.notFound.forEach((userIdentifier) => {
          console.log(userIdentifier);
        });
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });*/
  },
  updated() {
    // console.log(this.user);
  },
};
</script>